<template>
  <section class="mkp-products">
    <mkp-card
      v-for="(product, index) in products"
      :key="index"
      @click="productSelect(product)"
    >
      <template v-slot:header>
        <mkp-image
          v-if="product.media"
          :src="require('@/assets/images/' + product.media[0].url)"
          :lazy-src="require('@/assets/images/' + product.media[0].url)"
        />
      </template>
      <template v-slot:body>
        <mkp-text-field>{{ product.label }}</mkp-text-field>
      </template>
    </mkp-card>
  </section>
</template>

<script>
import MkpCard from "../../simple/card/mkp-card";
import MkpImage from "../../simple/image/mkp-image";
import MkpTextField from "../../simple/text-field/mkp-text-field";
import "./catalogue-scss/mkp-catalogue.scss";

export default {
  name: "MkpCatalogue",
  components: { MkpTextField, MkpImage, MkpCard },
  computed: {
    products() {
      return this.$store.getters.getProducts;
    }
  },
  methods: {
    productSelect(product) {
      this.$store.commit("SET_PRODUCT", product);
      this.$router.push({ name: "Product", params: { id: product.id } });
    }
  }
};
</script>

<style scoped></style>
