<template>
  <nav class="filter-carousel">
    <mkp-carousel
      class="nav-filter"
      :responsive="carouselOptions"
      :prevent-sroll-on-touch="'auto'"
    >
      <mkp-carrousel-slide
        v-for="(filter, index) in filters"
        :key="index"
        class="slide2"
      >
        <mkp-button :class="classes(filter)" @click="setFilter(filter)">
          {{ filter.label }}
        </mkp-button>
      </mkp-carrousel-slide>
      <template v-slot:prev-button>
        <mkp-image class="btn-prev" :lazy-src="navPrev" />
      </template>
      <template v-slot:next-button>
        <mkp-image class="btn-next" :lazy-src="navNext" />
      </template>
    </mkp-carousel>
  </nav>
</template>

<script>
import MkpCarousel from "../../complex/caroussel/mkp-carousel";
import MkpCarrouselSlide from "../../complex/caroussel/mkp-carousel-slide";
import MkpButton from "../../simple/button/mkp-button";
import MkpImage from "../../simple/image/mkp-image";
import "./catalogue-scss/mkp-filter-band.scss";
import { getValidBaseUrl } from "../../../utils/dataValidator.js";

export default {
  name: "MkpFilterBand",
  components: { MkpButton, MkpCarrouselSlide, MkpCarousel, MkpImage },
  data: () => {
    return {
      defaultFilter: { id: null, label: "TOUT", tags: null },
      navPrev: require("@/assets/images/arrow-left-black.svg"),
      navNext: require("@/assets/images/arrow-right-black.svg"),
      carouselOptions: {
        1441: {
          items: 4,
          gutter: 20,
          center: false
        },
        1280: {
          items: 4,
          gutter: 20,
          center: false
        },
        1024: {
          items: 4,
          gutter: 20
          //fixedWidth: 200,
          //edgePadding: 100
        },
        500: {
          items: 2,
          gutter: 0,
          center: false
        },
        200: {
          items: 1,
          center: true,
          touch: true
        }
      }
    };
  },
  computed: {
    filters() {
      let filters = [this.defaultFilter];
      return filters.concat(this.$store.getters.getCategories);
    },
    filterSet() {
      return this.$store.getters.getFilter;
    }
  },
  mounted() {
    this.loadProduct();
  },
  methods: {
    setFilter(filter) {
      this.$store.dispatch("setFilter", filter);
      this.loadProduct();
    },
    classes(filter) {
      return {
        active: filter.id === this.filterSet.id
      };
    },
    loadProduct() {
      if (this.filterSet.id) {
        this.$store.dispatch("loadProductsFilters", {
          url:
            getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/products",
          filter: this.filterSet.tags
        });
      } else {
        this.$store.dispatch(
          "loadProducts",
          getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/products"
        );
      }
    }
  }
};
</script>

<style scoped></style>
