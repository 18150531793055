<template>
  <div>
    <mkp-filter-band />
    <mkp-catalogue />
  </div>
</template>

<script>
import MkpFilterBand from "../../components/views/catalogue/mkp-filter-band";
import MkpCatalogue from "../../components/views/catalogue/mkp-catalogue";

export default {
  name: "Catalog",
  components: { MkpCatalogue, MkpFilterBand }
};
</script>

<style scoped></style>
